import { formatWithLetter } from "@presale-app/app/helpers";
import { useWhitelabel, useWhitelabelContracts } from "whitelabel-kit";

import { useVoucher } from "../../hooks/useVoucher";
import Loader from "../Loader/Loader";

const StatBlock: React.FC = () => {
  const { voucher } = useVoucher();
  const { addresses } = useWhitelabelContracts();

  const {
    whitelabelValues: {
      texts: {
        stableSymbol,
        currencyName,
        stageName,
        start,
        end,
        currentPrice,
        nextStage,
        discount,
        maxDeposit,
        totalProceeds,
        viewContracts,
      },
      contract,
    },
  } = useWhitelabel();
  if (!voucher) {
    if (addresses?.voucher) {
      return <Loader className="p-20" />;
    } else {
      return (
        <div className="text-gray text-p1 p-24 rounded-large bg-whitelabel-main-800 my-24 ">
          Voucher has not been deployed
        </div>
      );
    }
  }

  if (
    !voucher.currentPresaleRound ||
    !voucher.currentPresaleRoundSoldVtrs ||
    !voucher.currentPresaleRoundAllVtrs
  ) {
    if (!addresses?.voucher) {
      return <Loader className="p-20" />;
    } else {
      return (
        <div className="text-gray text-p1 p-24 rounded-large bg-whitelabel-main-800 my-24 ">
          There are no rounds
        </div>
      );
    }
  }

  return (
    <div className="text-gray text-p1 p-24 rounded-large bg-whitelabel-main-800 my-24 ">
      <div className="flex gap-8 sm:!flex-wrap">
        <span className="sm:!flex-[0_1_100%]">{`${stageName} Stage ${voucher.currentPresaleRoundNumber} of ${voucher.allPresaleRoundCount}`}</span>
        <span className="m-0 smin:!hidden">
          Sold{" "}
          <span className="text-white">
            {formatWithLetter(voucher.currentPresaleRoundSoldVtrs)}
          </span>
          <span className="text-p3 ml-4">{currencyName}</span>
        </span>
        <span className="smin:!hidden">
          of{" "}
          <span className="text-white">{formatWithLetter(voucher.currentPresaleRoundAllVtrs)}</span>
          <span className="text-p3 ml-4">{currencyName}</span>
        </span>

        <span className="ml-auto sm:!hidden">
          Sold <span className="text-white">{voucher.currentPresaleRoundSoldVtrs}</span>
          <span className="text-p3 ml-4">{currencyName}</span>
        </span>
        <span className="sm:!hidden">
          of <span className="text-white">{voucher.currentPresaleRoundAllVtrs}</span>
          <span className="text-p3 ml-4">{currencyName}</span>
        </span>
        <span>
          <span className="text-white">{`(${voucher.vtrsSoldPercentage}%)`}</span>
        </span>
      </div>
      <div className="rounded-full gradientBg h-8 flex justify-end my-32">
        <div
          className="rounded-full bg-whitelabel-main-800 m-1"
          style={{
            width: `${Math.min(100 - voucher.currentPresaleRound.progress * 100, 95)}%`,
          }}
        ></div>
      </div>
      <div className="grid grid-cols-2 gap-y-16 sm:!flex sm:!flex-col sm:!gap-8">
        <div>
          {start}
          <span className="ml-16 text-white">{voucher.currentPresaleRound.startTime}</span>
        </div>
        <div className="sm:!order-1">
          {discount}{" "}
          <span className="ml-16 text-white">{voucher.currentPresaleRoundDiscount}%</span>
        </div>
        <div>
          {end} <span className="ml-16 text-white">{voucher.currentPresaleRound.endTime}</span>
        </div>
        <div className="sm:!order-2">
          {maxDeposit}
          <span className="ml-16 text-white">
            {stableSymbol}
            {voucher.availableDeposit}
          </span>
        </div>
        <div>
          {currentPrice}
          <span className="ml-16 text-white">
            {stableSymbol}
            {voucher.currentPresaleRoundPrice}
          </span>
        </div>
        <div className="sm:!order-3">
          {totalProceeds}
          <span className="ml-16 text-white">
            {stableSymbol}
            {voucher.totalProceeds}
          </span>
        </div>
        <div>
          {nextStage}{" "}
          <span className="ml-16 text-white">
            {stableSymbol}
            {voucher.nextPrice}
          </span>
        </div>
        <div className="flex items-center sm:!order-4">
          {viewContracts}
          <span className="flex">
            <a
              href={`${contract?.network?.etherscanLink}${addresses?.voucher}`}
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-24 ml-16" src="./assets/images/etherscan-logo-circle.svg" alt="" />
            </a>
            <a
              href={`${contract?.network?.openSeaLink}${addresses?.voucher}`}
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-24 ml-16" src="./assets/images/open-sea-logo.svg" alt="" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatBlock;
