import cx from "classnames";
import { VideoComponent } from "vit-ui-kit";
import { useWhitelabel } from "whitelabel-kit";

import { isVideo } from "../../../helpers";
import { Deposit } from "../../../models/Deposit";

interface VoucherProps extends Deposit {
  className?: string;
}

const Voucher: React.FC<VoucherProps> = ({ className, amount, id, allocation }) => {
  const {
    whitelabelValues: {
      images: {
        smallVoucher: { url },
      },
      texts: { voucherName, allocationName, currencyName, stableSymbol },
    },
  } = useWhitelabel();

  return (
    <div
      className={cx(
        "flex items-center py-16 px-32 rounded-large border border-whitelabel-main-800 sm:!px-16",
        className,
      )}
    >
      <div
        className="mr-20 flex align-center items-center bgCover w-[35rem] h-[35rem]"
        style={{
          background: `url(${url}) no-repeat center/100% `,
        }}
      >
        {isVideo(url) && (
          <VideoComponent
            autoplay
            muted
            controls={false}
            loop
            className="h-full m-auto"
            url={url}
          />
        )}
      </div>

      <div className="flex items-center flex-auto sm:!block">
        <div className="mr-8">
          <div className="mb-8">
            {voucherName} <span className="text-white">#{id}</span>
          </div>
          <div>
            {allocationName} <span className="text-white">{allocation}</span>
            <span className="uppercase text-p3 ml-8">{currencyName}</span>
          </div>
        </div>
        <div className="text-white ml-auto mt-8">
          {stableSymbol}
          {amount}
        </div>
      </div>
    </div>
  );
};

export default Voucher;
