import { useQuery } from "@tanstack/react-query";

import { config } from "../config/environment.config";

export interface GetSignatureResponse {
  signatureEIP712: "string";
  _id: "string";
  address: "string";
}

export const useGetSignature = ({ hash }: { hash?: string }) =>
  useQuery([`getAffiliateSignature${hash}`], async () => {
    if (!hash) {
      return null;
    }

    try {
      const response = await fetch(config.affiliateApiUrl + `/user/signature/` + hash);
      const data: GetSignatureResponse = await response.json();

      return {
        signature: data.signatureEIP712,
        address: data.address,
      };
    } catch (error) {
      return { signature: "", address: "" };
    }
  });
