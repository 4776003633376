import { useMutation } from "@tanstack/react-query";
import { SmartContract } from "@thirdweb-dev/react";
import { BaseContract } from "ethers/lib/ethers";

export const useApproveUsdc = (VOUCHER_ADDRESS?: string, contract?: SmartContract<BaseContract>) =>
  useMutation(async ({ value }: { value: string }) => {
    if (!VOUCHER_ADDRESS) throw Error("There is no VOUCHER_ADDRESS");

    return await contract?.erc20.setAllowance(VOUCHER_ADDRESS, Number(value));
  });
