import { useWhitelabel } from "whitelabel-kit";

import { useVoucher } from "../../hooks/useVoucher";
import Loader from "../Loader/Loader";
import Voucher from "./Voucher/Voucher";

const VoucherList: React.FC = () => {
  const { deposits } = useVoucher();

  const {
    whitelabelValues: {
      texts: { recentTransactions },
    },
  } = useWhitelabel();

  if (!deposits) {
    return <Loader className="p-20" />;
  }

  return (
    <div className="text-p1 mt-24 sm:!text-p3">
      <div className="mb-24 text-white">{recentTransactions}</div>
      <div className="text-gray">
        {deposits.map((deposit) => (
          <Voucher
            key={deposit.amount + deposit.id + deposit.round + deposit.depositId}
            className="mt-24"
            {...deposit}
          />
        ))}
      </div>
    </div>
  );
};

export default VoucherList;
