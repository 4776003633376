export const namings = {
  "header-user-icon": "User/Header icon",
  "whitelabel-main": "Whitelabel admin main",
  // "whitelabel-main-200": "#466563",
  "whitelabel-main-300": "Whitelabel color 1",
  "whitelabel-main-400": "Header border and Mobile footer background",
  "whitelabel-main-600": "Whitelabel color 4",
  "whitelabel-main-700": "Connect hover bg",
  "whitelabel-main-800": "Whitelabel color 2",
  "vitreus-black": "Whitelabel color 5",
  "vitreus-main-black-green": "Whitelabel color 6",
  "vitreus-black-800": "Whitelabel color 7",
  "vitreus-gray": "Whitelabel color 8",
  "vitreus-white": "Whitelabel color 9",
  // "vitreus-purple": "#5790ff",
  "vitreus-luminous-green": "Whitelabel color 3",
  "vitreus-luminous-green-100": "Presale color 1",
  // "vitreus-luminous-green-200": "#a7f7d1",
  // "vitreus-luminous-green-400": "#62d9a0",
  // "vitreus-luminous-green-600": "#57c18e",
  // "vitreus-luminous-green-800": "#4ca97d",
  // "vitreus-luminous-yellow": "#eff16d",
  // "vitreus-orange": "#eebc6f",
  "vitreus-luminous-blue": "Affiliate gradient color",
  "vitreus-blue": "Presale button 1",
  "vitreus-dark-blue": "Presale color 2",
  "vitreus-sky-blue": "Presale button 1",
  "vitreus-leaf": "Affiliate color 1",
  "vitreus-red": "Whitelabel red",
  // "whitelabel-gradient-light-green": "#6DF1B2",
  // "whitelabel-gradient-dark-blue": "#1CA0C6",
  // "whitelabel-gradient-dark-green": "#6df1b2",
  // "whitelabel-gradient-light-blue": "#1ac5f9",
  // "whitelabel-gradient-yellow": "#eff16d",
};
