import { useError } from "@presale-app/app/hooks/useError";
import { StrokeButton } from "vit-ui-kit";

import { ModalProps } from "../types";

const ErrorModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  const { error } = useError();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getErrorText = () => {
    if (error.reason) {
      return capitalizeFirstLetter(error.reason);
    }

    if (error.message.indexOf(`TRANSACTION INFORMATION`) !== -1) {
      return (
        <>
          Something went wrong.
          <br />
          <br />
          {error.message
            .slice(
              error.message.indexOf(`TRANSACTION INFORMATION`) + 54,
              error.message.indexOf(`DEBUGGING RESOURCES`) - 28,
            )

            .split("<br />")
            .map((string: string) => (
              <>
                {string}
                <br />
              </>
            ))}
        </>
      );
    }

    return capitalizeFirstLetter(error.message);
  };

  return (
    <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="absolute bg-black opacity-70 top-0 left-0 w-full h-full" />
      <div className="w-[500rem] min-h-[300rem] flex flex-col items-center justify-between bg-whitelabel-main-800 rounded-large animate-fade-in p-40 mx-20 z-10">
        <div className="text-p1 w-full text-vitreus-luminous-green text-center">
          {getErrorText()}
        </div>
        <StrokeButton className="w-1/2 mt-20" text="Got it" onClick={() => setActiveModal("")} />
      </div>
    </div>
  );
};

export default ErrorModal;
