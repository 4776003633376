import { useHandleAffiliate } from "@presale-app/app/actions/usePressMainButton";
import { SmartContract } from "@thirdweb-dev/react-core";
import cx from "classnames";
import { BaseContract } from "ethers/lib/ethers";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, StrokeButton } from "vit-ui-kit";
import { useWhitelabel, useWhitelabelContracts } from "whitelabel-kit";

import { ReactComponent as Cross } from "../../../../../assets/images/cross.svg";
import { useError, usePercentButton, useUSDC, useUser, useVoucher } from "../../../../hooks";
import { BlueBtn, MintBtn, SecondaryBtn } from "../../../../ui-kit";
import Loader from "../../../Loader/Loader";
import { Errors } from "../error.enum";
import { type ModalProps } from "../types";

const TransactionModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const cacheRef = useRef<string>("");
  const [inputValue, setInput] = useState("");
  const {
    whitelabelValues: {
      envs,
      texts: { stableName, nativeName },
    },
  } = useWhitelabel();
  const { stableDecimals } = useWhitelabelContracts();

  const { setError, parseError } = useError();

  const [error, setErrorMessage] = useState("");

  const { isLoading: usdcLoading, usdcBalance, ethBalance } = useUser();

  const { isLoading: isVoucherLoading, mutateAsync: handleMint } = useHandleAffiliate({
    ...envs,
    USDC_DECIMALS: stableDecimals,
  });
  const { refetch, voucher } = useVoucher();
  const { allowance, approve, isLoading: approveLoading, refetchAllowance } = useUSDC();

  const [activeCurrency, setActiveCurrency] = useState<string>(nativeName);
  const { getFullCost, getPartCost } = usePercentButton(activeCurrency, voucher);
  const [nextStage, setNextStage] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const isMintDisabled = useMemo(() => !usdcBalance || usdcLoading, [usdcBalance, usdcLoading]);

  const setInputValue = useCallback(
    (value: string) => {
      if (inputRef.current) {
        inputRef.current.value = value;
        cacheRef.current = value;
      } else {
        cacheRef.current = value;
      }
    },
    [inputRef.current],
  );

  const onValueChangeHandler = () => {
    const errorDesc = validate();
    if (errorDesc) {
      setErrorMessage(errorDesc);
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (activeCurrency === nativeName) {
      setNextStage(false);
    } else {
      if (allowance >= Number(inputRef.current?.value) && activeCurrency === stableName) {
        setNextStage(false);
      } else {
        setNextStage(true);
      }
    }
    onValueChangeHandler();
  }, [activeCurrency, inputValue]);

  const inputRefresh = () => {
    inputRef.current?.focus();
    inputRef.current?.blur();
  };

  const validate = () => {
    if (isNaN(Number(inputRef.current?.value))) {
      setDisabled(true);
      return Errors.WrongValueType;
    }

    if (inputRef.current?.value === "") {
      setDisabled(true);
      return Errors.EmptyField;
    }

    if (
      Number(inputRef.current?.value) > Number(voucher?.availableDepositNumber) &&
      activeCurrency === stableName
    ) {
      setDisabled(true);
      return Errors.MoreThanMaxDep;
    }

    if (
      Number(inputRef.current?.value) > Number(voucher?.availableDepositNumberInETH) &&
      activeCurrency === nativeName
    ) {
      setDisabled(true);
      return Errors.MoreThanMaxDep;
    }

    if (Number(inputRef.current?.value) <= 0) {
      setDisabled(true);
      return Errors.NegativeNumber;
    }

    if (Number(inputRef.current?.value) > Number(usdcBalance) && activeCurrency === stableName) {
      setDisabled(true);
      return Errors.InvalidAmount;
    }

    if (
      inputRef.current?.value &&
      inputRef.current.value.split(".").length === 2 &&
      inputRef.current.value.split(".")[1].length > 6 &&
      activeCurrency === stableName
    ) {
      setDisabled(true);
      return Errors.ExceedsDecimals;
    }

    if (allowance < Number(inputRef.current?.value) && activeCurrency === stableName) {
      setDisabled(true);
      setNextStage(true);
      return Errors.NeedApprove;
    }

    if (Number(inputRef.current?.value) > Number(ethBalance) && activeCurrency === nativeName) {
      setDisabled(true);
      return Errors.InvalidAmount;
    }

    setDisabled(false);
    return "";
  };

  const handleMintClick = useCallback(
    (contract: SmartContract<BaseContract>) => {
      cacheRef.current = inputRef.current?.value || "";

      return handleMint({
        contract,
        currency: activeCurrency,
        isHaveVoucher: !!voucher?.currentVoucher,
        value: inputRef?.current?.value.trim() || "0",
      });
    },
    [allowance, activeCurrency, !!voucher?.currentVoucher],
  );

  if (isVoucherLoading || approveLoading) {
    return (
      <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
        <div className="absolute bg-black opacity-70 top-0 left-0 w-full h-full" />
        <Loader />
      </div>
    );
  }

  return (
    <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="absolute bg-black opacity-70 top-0 left-0 w-full h-full" />
      <div className="w-[700rem] bg-whitelabel-main-800 rounded-large animate-fade-in relative mx-20">
        <div
          className="absolute left-auto top-5 right-5 w-24 h-24 cursor-pointer"
          onClick={() => setActiveModal("")}
        >
          <Cross className="icon-success" />
        </div>
        <div className="px-24 py-48">
          <div className="flex items-start gap-16">
            <Input
              className="flex-auto"
              ref={inputRef}
              onChange={(event) => {
                setInput(event.currentTarget.value);
              }}
              value={inputValue}
              error={error}
              defaultValue={cacheRef.current}
              // onBlur={onValueChangeHandler}
            />
            <div className="text-p1 text-white h-48 flex items-center">
              <span
                className={cx("cursor-pointer mr-8", {
                  "text-gray": activeCurrency !== nativeName,
                  "underline text-[20rem] leading-[20rem]": activeCurrency === nativeName,
                })}
                onClick={async () => {
                  await setActiveCurrency(nativeName);
                }}
              >
                {nativeName}
              </span>
              <span
                className={cx("cursor-pointer", {
                  "text-gray": activeCurrency !== stableName,
                  "underline text-[20rem] leading-[20rem]": activeCurrency === stableName,
                })}
                onClick={async () => {
                  await setActiveCurrency(stableName);
                }}
              >
                {stableName}
              </span>
            </div>
          </div>
          <div className="mt-16 mb-48 gap-8 flex flex-wrap items-center text-p1 text-gray">
            <MintBtn
              className="oneHundredPercent"
              text="100 %"
              onClick={async (contract) => {
                if (activeCurrency === nativeName) {
                  const estimation = await handleMint({
                    contract,
                    currency: activeCurrency,
                    isHaveVoucher: !!voucher?.currentVoucher,
                    value: "0.00001",
                    isEstimate: true,
                  });

                  setInputValue(getFullCost(estimation));
                  setInput(getFullCost(estimation));
                  inputRef.current?.focus();
                } else {
                  const value = String(
                    Math.min(Number(voucher?.availableDepositNumber), Number(usdcBalance)),
                  );
                  setInput(value);
                  setInputValue(value);
                  inputRef.current?.focus();
                }
              }}
            />
            <StrokeButton
              text="75 %"
              onClick={async () => {
                const part = await getPartCost(75);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            <StrokeButton
              text="50 %"
              onClick={async () => {
                const part = await getPartCost(50);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            <StrokeButton
              text="25 %"
              onClick={async () => {
                const part = await getPartCost(25);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            of your balance
          </div>
        </div>
        {!nextStage ? (
          <div className="grid grid-cols-2">
            <SecondaryBtn
              className="rounded-bl-large"
              text="cancel"
              onClick={() => setActiveModal("")}
              rounded={false}
            />
            <MintBtn
              className="rounded-br-large doMint"
              text="mint"
              refresh={inputRefresh}
              onClick={(e) => {
                if (validate()) return;
                return handleMintClick(e);
              }}
              rounded={false}
              onSuccess={async (e) => {
                refetch();
                setActiveModal("successModal");
              }}
              onError={(e) => {
                setError(parseError(e));
                setActiveModal("errorModal");
              }}
              disabled={isMintDisabled || disabled}
            />
          </div>
        ) : (
          <BlueBtn
            text="approve"
            className="w-full"
            disabled={disabled}
            onClick={async () => {
              try {
                await approve({
                  value: String((Number(inputRef.current?.value) + 0.01).toFixed(6)).trim() || "0",
                });
                await refetchAllowance();
                setActiveModal("approveModal");
              } catch (e) {
                setError(parseError(e));
                setActiveModal("errorModal");
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionModal;
