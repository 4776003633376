import { useContract } from "@thirdweb-dev/react";
import { useWhitelabel, useWhitelabelContracts } from "whitelabel-kit";

import { useApproveUsdc } from "../actions/useApprove";
import { useGetUSDC } from "../actions/useGetUSDC";

export const useUSDC = () => {
  const {
    whitelabelValues: { envs },
  } = useWhitelabel();
  const {
    addresses,
    stableAddress,
    abis: { stable },
  } = useWhitelabelContracts();
  const { contract, isLoading: contractLoading } = useContract(stableAddress, stable);
  const { data, isLoading: allowanceLoading, refetch } = useGetUSDC(addresses?.voucher, contract);
  const { mutateAsync, isLoading } = useApproveUsdc(addresses?.voucher, contract);

  return {
    allowance: data?.displayValue ? Number(data?.displayValue) : 0,
    refetchAllowance: refetch,
    approve: mutateAsync,
    isLoading: contractLoading || isLoading || allowanceLoading,
  };
};
