import { Routes } from "@presale-app/app/app";
import { FC, memo, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useWhitelabelAdmin } from "whitelabel-kit";

export const WhiteLabelGuard: FC<{ children: ReactElement }> = memo(({ children }) => {
  const { user } = useWhitelabelAdmin();

  if (!user) {
    return <Navigate to={Routes.WLLogin} />;
  }

  return children;
});
