import { StrokeButton } from "vit-ui-kit";

import { ReactComponent as Check } from "../../../../../assets/images/check.svg";
import { type ModalProps } from "../types";

const SuccessModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  return (
    <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="absolute bg-black opacity-70 top-0 left-0 w-full h-full" />
      <div className="w-[420rem] bg-whitelabel-main-800 rounded-large py-64 px-40 flex flex-col items-center mx-20 z-10">
        <Check className="icon-success" />
        <span className="text-sh2 text-vitreus-luminous-green my-16 text-center">
          Transaction successful
        </span>
        <span className="text-p1 text-gray mb-60 text-center">
          Congratulations! You successfully received the VTRS on your Vitreus voucher.
        </span>
        <StrokeButton text="Close" onClick={() => setActiveModal("")} fullWidth />
      </div>
    </div>
  );
};

export default SuccessModal;
