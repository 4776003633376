import cx from "classnames";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type LinkProps = {
  className?: string;
  text: string;
  textStyle?: string;
  blank?: boolean;
} & RouterLinkProps;

const Link: React.FC<LinkProps> = ({ to, text, textStyle = "text-p3", className, blank }) => {
  return (
    <RouterLink
      className={cx("text-vitreus-luminous-green py-8 px-16 uppercase", className, textStyle)}
      to={to}
      target={blank ? "_blank" : "_self"}
    >
      {text}
    </RouterLink>
  );
};

export default Link;
