import { useMutation } from "@tanstack/react-query";

import { config } from "../config/environment.config";

export const useRegisterUrl = () =>
  useMutation(async (address?: string) => {
    const params = new URL(window.location.href).searchParams;
    const signature = params.get("hash");

    const headers = new Headers();
    headers.set("Content-Type", "application/json");

    const response = await fetch(config.affiliateApiUrl + "/affiliate", {
      method: "POST",
      body: JSON.stringify({ signature, address }),
      headers,
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  });
