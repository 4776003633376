import { useCheckUrl } from "@presale-app/app/actions/useCheckUrl";
import { useRegisterUrl } from "@presale-app/app/actions/useRegisterUrl";
import { config } from "@presale-app/app/config/environment.config";
import { ShieldIcon } from "@presale-app/app/icons";
import { useAddress } from "@thirdweb-dev/react";
import cx from "classnames";
import { FC, ReactElement, useEffect } from "react";

import BackImages from "../BackImages/BackImages";
import Loader from "../Loader/Loader";

const params = new URL(window.location.href).searchParams;
const isFromAffiliate = window.location.host === config.restrictedHost;
const isFromAffiliateProgram = isFromAffiliate && params.has("hash");

export const AffiliateInitializer: FC<{ children: ReactElement }> = ({ children }) => {
  const { data: checkUrlData, error: checkUrlError, mutate: checkUrl } = useCheckUrl();
  const { mutate: registerUrl } = useRegisterUrl();
  const address = useAddress();

  useEffect(() => {
    if (isFromAffiliateProgram) {
      checkUrl();
    }
  }, []);

  useEffect(() => {
    if (address && isFromAffiliate) {
      registerUrl(address);
    }
  }, [address]);

  if (checkUrlError) {
    return (
      <div
        className={cx(
          "landing bg-black flex-auto flex flex-col items-center justify-center w-full h-screen",
        )}
      >
        <BackImages />
        <div className=" flex p-20 rounded-[20rem] flex-col  border-[1rem] border-vitreus-red max-w-[460rem] w-[70%] z-20">
          <div className="flex gap-8 items-center">
            <ShieldIcon className="w-[32rem]" />
            <span className="text-white text-[28rem]">Error!</span>
          </div>
          <span className="text-white text-[16rem] ml-[40rem]">
            Link is incorrect. Check it and try again.
          </span>
        </div>
      </div>
    );
  }

  if (checkUrlData || !isFromAffiliateProgram) {
    return children;
  }

  return (
    <div className={cx("landing pt-72 bg-black flex-auto flex flex-col items-stretch")}>
      <Loader className="p-20" />
    </div>
  );
};
