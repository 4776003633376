import { FC, ReactElement } from "react";

import { CurrencyProvider } from "../hooks/useCurrency";
import { DrawerProvider } from "../hooks/useDrawer";
import { ErrorProvider } from "../hooks/useError";
import { InputValueProvider } from "../hooks/useInputValue";

export const ContextProviders: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  return (
    <ErrorProvider>
      <DrawerProvider>
        <InputValueProvider>
          <CurrencyProvider>{children}</CurrencyProvider>
        </InputValueProvider>
      </DrawerProvider>
    </ErrorProvider>
  );
};
