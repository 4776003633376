import { createContext, Dispatch, FC, ReactElement, useContext, useState } from "react";

export const Drawer = createContext({
  drawer: false,
  setDrawer: (() => null) as Dispatch<React.SetStateAction<boolean>>,
});
export const useDrawer = () => useContext(Drawer);

export const DrawerProvider: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  const [drawer, setDrawer] = useState(false);

  return <Drawer.Provider value={{ drawer, setDrawer }}>{children}</Drawer.Provider>;
};
