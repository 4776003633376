import { VideoComponent } from "vit-ui-kit";
import { useWhitelabel } from "whitelabel-kit";

import { isVideo } from "../../helpers";
import { useVoucher } from "../../hooks/useVoucher";
import Loader from "../Loader/Loader";

const VoucherBig: React.FC = () => {
  const { voucher } = useVoucher();

  const {
    whitelabelValues: {
      images: {
        bigVoucher: { url },
      },
      texts: { voucherName, allocationName, valueName, stableShortName, currencyName },
    },
  } = useWhitelabel();
  if (!voucher) {
    return <Loader className="p-20" />;
  }

  if (!voucher.currentVoucher) {
    return null;
  }

  return (
    <div className="gradientBg text-p1 p-1 rounded-large text-gray sm:!text-p3">
      <div className="bg-black rounded-large flex p-24 pr-64 gap-24 items-center justify-between sm:!p-12 sm:!gap-8">
        <div className="w-80 h-80 flex items-center justify-center sm:!h-60">
          <div
            className=" flex align-center items-center w-[48rem] h-[48rem] bgCover"
            style={{
              background: `url(${url}) no-repeat center/100%`,
            }}
          >
            {isVideo(url) && (
              <VideoComponent
                autoplay
                muted
                controls={false}
                loop
                className="h-full m-auto"
                url={url}
              />
            )}
          </div>
        </div>
        <div>
          <div>{voucherName}</div>
          <div className="text-white mt-8">#{voucher.currentVoucher.id}</div>
        </div>
        <div>
          <div>{allocationName}</div>
          <div className="text-white mt-8">
            {voucher.currentVoucher.allocation}{" "}
            <span className="uppercase text-gray text-p3">{currencyName}</span>
          </div>
        </div>
        <div>
          <div>{valueName}</div>
          <div className="text-white mt-8">
            {voucher.currentVoucher.value}{" "}
            <span className="uppercase text-gray text-p3">{stableShortName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherBig;
