export const config = {
  isDev: process.env.REACT_APP_DEVELOPMENT,
  WALLET_CONNECT_PROJECT_ID: process.env.WALLET_CONNECT_PROJECT_ID,
  affiliateApiUrl: process.env.AFFILIATE_API_URL,
  restrictedHost: process.env.RESTRICTED_PRESALE_HOST,
  SENTRY_DSN: process.env.SENTRY_DSN,
  PRESALE_API: process.env.PRESALE_API,
  DEFAULT_THEME_ID: process.env.DEFAULT_THEME_ID,
  X_API_KEY: process.env.X_API_KEY,
  WEB_3_STORAGE_KEY: process.env.WEB_3_STORAGE_KEY,
};
