import cx from "classnames";
import { Header as HeaderUI, VideoComponent } from "vit-ui-kit";
import { useWhitelabel } from "whitelabel-kit";

import { isVideo } from "../../helpers";
import { ConnectWalletBtn, Link } from "../../ui-kit";
import WidthWrapper from "../WidthWrapper/WidthWrapper";

//1270px

const Header = () => {
  const {
    whitelabelValues: { images, links, helmet },
  } = useWhitelabel();

  return (
    <HeaderUI
      text={helmet.title}
      Logo={
        <>
          <div
            className="sm:!hidden flex align-center items-center w-[120rem] h-[60rem] bgCover"
            style={{
              background: `url(${images.logo.url}) no-repeat center/100%`,
            }}
          >
            {isVideo(images.logo.url) && (
              <VideoComponent
                autoplay
                muted
                controls={false}
                loop
                className="h-full m-auto"
                url={images.logo.url}
              />
            )}
          </div>
          <div
            className="hidden sm:!block align-center items-center w-[120rem] h-[60rem] bgCover"
            style={{
              background: `url(${images.smallLogo.url}) no-repeat center/100%`,
            }}
          >
            {isVideo(images.smallLogo.url) && (
              <VideoComponent
                autoplay
                muted
                controls={false}
                loop
                className="h-full m-auto"
                url={images.smallLogo.url}
              />
            )}
          </div>
        </>
      }
      className="bg-whitelabel-main-800 border-b-whitelabel-main-400 border-b fixed w-full z-10 top-0"
    >
      <WidthWrapper className="py-20 flex ">
        {links.header.map(({ name, href }, index) => (
          <Link
            key={index}
            className={cx("uppercase sm:!hidden", {
              "ml-auto": index === 0,
              "ml-24": index !== 0,
            })}
            to={href}
            text={name}
            blank
          />
        ))}

        <ConnectWalletBtn className="ml-24 sm:!ml-auto" />
      </WidthWrapper>
    </HeaderUI>
  );
};

export default Header;
