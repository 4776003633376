import { QueryClientProvider } from "@tanstack/react-query";
import { coinbaseWallet, metamaskWallet, safeWallet, walletConnect } from "@thirdweb-dev/react";
import { Navigate, Outlet, RouterProvider } from "react-router";
import { createBrowserRouter, useSearchParams } from "react-router-dom";
import { Invite, WhitelabelLogin, WhitelabelProvider } from "whitelabel-kit";

import loginBackground from "./bg.png";
import { AdminPanel } from "./components/AdminPanel/AdminPanel";
import { AffiliateInitializer } from "./components/AffiliateInitializer/AffiliateInitializer";
import { ContextProviders } from "./components/Context";
import { baseTheme, cssVars } from "./config/baseTheme";
import { config } from "./config/environment.config";
import { namings } from "./config/namings";
import { queryClient } from "./config/queryClient.config";
import Sentry from "./config/sentry.config";
import { FAQ } from "./pages/Faq";
import { KYC } from "./pages/KYC";
import { Landing } from "./pages/Landing";

export enum Routes {
  Home = "/",
  FAQ = "/faq",
  KYC = "/kyc",
  Landing = "/landing",
  WLAdmin = "/whitelabel-admin",
  WLLogin = "/whitelabel-login",
  WLInvite = "/whitelabel-invite",
}

const ProvidersComponent = () => {
  const [searchParams] = useSearchParams();

  const keyFromSearch = searchParams.get("xApiKey");
  const xApiKey =
    (keyFromSearch && keyFromSearch.length && keyFromSearch !== "none" && keyFromSearch !== ""
      ? keyFromSearch
      : undefined) ||
    (config.X_API_KEY &&
    config.X_API_KEY.length &&
    config.X_API_KEY !== "none" &&
    config.X_API_KEY !== '""' &&
    config.X_API_KEY !== "''"
      ? config.X_API_KEY
      : undefined);

  return (
    <QueryClientProvider client={queryClient}>
      <WhitelabelProvider
        namings={namings}
        whitelabelApiUrl={config.PRESALE_API}
        themeId={searchParams.get("themeId") || config.DEFAULT_THEME_ID}
        xApiKey={xApiKey}
        w3sKey={config.WEB_3_STORAGE_KEY}
        queryClient={queryClient}
        baseTheme={baseTheme}
        cssVars={cssVars}
        supportedWallets={[
          metamaskWallet() as any,
          coinbaseWallet(),
          walletConnect(),
          safeWallet(),
        ]}
        autoSwitch
        // debug
      >
        <ContextProviders>
          <Outlet />
        </ContextProviders>
      </WhitelabelProvider>
    </QueryClientProvider>
  );
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <ProvidersComponent />,
    children: [
      {
        path: "/",
        element: (
          <AffiliateInitializer>
            <Outlet />
          </AffiliateInitializer>
        ),
        children: [
          {
            path: Routes.Home,
            element: <Landing />,
            // index: true,
            children: [
              {
                path: Routes.WLAdmin,
                element: <AdminPanel />,
              },
            ],
          },
          {
            path: Routes.WLLogin,
            element: (
              <WhitelabelLogin
                backgroundImageUrl={loginBackground}
                OnLoginComponent={<Navigate to={Routes.WLAdmin} />}
              />
            ),
          },
          // TODO: remove
          {
            path: Routes.WLInvite,
            element: (
              <Invite
                xApiKey={
                  config.X_API_KEY && config.X_API_KEY !== "none"
                    ? config.X_API_KEY
                    : "498e2f97-ac6d-4aac-a2ca-5a9b83d6ed0d"
                }
              />
            ),
          },

          { path: Routes.FAQ, element: <FAQ /> },
          { path: Routes.KYC, element: <KYC /> },
        ],
      },
    ],
  },
  { path: "*", element: <Navigate to={"/"} /> },
]);

// TODO: images, layouts, loader, animations

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
