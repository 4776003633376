import cx from "classnames";

type WidthWrapperProps = {
  className?: string;
  children: React.ReactNode[] | React.ReactNode;
};

const WidthWrapper: React.FC<WidthWrapperProps> = ({ children, className }) => {
  return <div className={cx("max-w-main m-auto px-20", className)}>{children}</div>;
};

export default WidthWrapper;
