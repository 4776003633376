import cx from "classnames";
import React from "react";

import { ButtonProps } from "../ui-kit-types";

const PrimaryBtn: React.FC<ButtonProps> = ({
  className,
  onClick,
  text,
  fullWidth,
  disabled = false,
}) => {
  return (
    <button
      className={cx(
        "bg-vitreus-luminous-green uppercase text-black py-19 px-24 text-p3 rounded-small hover:bg-vitreus-luminous-green-100 transition-colors duration-300 font-medium",
        className,
        {
          "w-full": fullWidth,
          "opacity-50": disabled,
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default PrimaryBtn;
