import React, { ReactElement } from "react";

import {
  ApproveModal,
  DisclaimerModal,
  ErrorModal,
  SuccessModal,
  TransactionModal,
} from "./components";

type ModalsContainerProps = {
  activeModal?: string;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
  parentRef?: React.RefObject<HTMLDivElement>;
};

const modalList: Record<string, ReactElement> = {
  transactionModal: <TransactionModal />,
  successModal: <SuccessModal />,
  errorModal: <ErrorModal />,
  disclaimerModal: <DisclaimerModal />,
  approveModal: <ApproveModal />,
};

const ModalsContainer: React.FC<ModalsContainerProps> = ({
  activeModal,
  setActiveModal,
  parentRef,
}) => {
  if (!activeModal) {
    if (parentRef?.current) {
      parentRef.current.style.overflow = "auto";
    }

    return <div></div>;
  } else {
    if (parentRef?.current) {
      parentRef.current.style.overflow = "hidden";
    }
  }

  return <div>{React.cloneElement(modalList[activeModal], { setActiveModal })}</div>;
};

export default ModalsContainer;
