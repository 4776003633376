import {
  ArrowDownIcon,
  ArrowSideIcon,
  AuditIcon,
  HelpIcon,
  QuestionIcon,
  UserIcon,
} from "@presale-app/app/icons";
import { IframeLink } from "@presale-app/app/ui-kit";
import cx from "classnames";
import React, { useState } from "react";

const MobileFooter: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed w-full h-[80rem] bg-black z-10 px-8 bottom-0 left-0 flex items-center justify-between border-t-whitelabel-main-400 border-t smin:!hidden">
      <IframeLink
        to="https://vitreus.io/vtrs-disclaimer/"
        className="flex-[0_1_15%]"
        textClassName="px-0"
        onClick={setIsOpen}
      >
        <HelpIcon className="w-36 mb-6" />
        <div className="text-p4 text-white">Agreement</div>
      </IframeLink>
      <IframeLink
        to="https://vitreus.io/vtrs-kyc/"
        className="flex-[0_1_15%]"
        textClassName="px-0"
        onClick={setIsOpen}
      >
        <UserIcon className="w-36 mb-6" />
        <div className="text-p4 text-white">KYC</div>
      </IframeLink>
      <div
        className="arrows relative z-10 bg-whitelabel-gradient-main flex-[0_0_85rem] w-[85rem] h-[85rem] rounded-full flex flex-col justify-center items-center cursor-pointer"
        onClick={() => setIsOpen(false)}
      >
        <ArrowDownIcon className={cx("w-40", { hidden: !isOpen })} />
        <p className={cx("text-p4 text-black", { hidden: !isOpen })}>Close</p>
        <ArrowSideIcon className={cx("w-64", { hidden: isOpen })} />
      </div>
      <IframeLink
        to="https://vitreus.io/vtrs-audit/"
        className="flex-[0_1_15%]"
        textClassName="px-0"
        onClick={setIsOpen}
      >
        <AuditIcon className="w-36 mb-6" />
        <div className="text-p4 text-white">Audit</div>
      </IframeLink>
      <IframeLink
        to="https://vitreus.io/faq-vtrs/"
        className="flex-[0_1_15%]"
        textClassName="px-0"
        onClick={setIsOpen}
      >
        <QuestionIcon className="w-36 mb-6" />
        <div className="text-p4 text-white">FAQ</div>
      </IframeLink>
    </div>
  );
};

export default MobileFooter;
