import { createContext, FC, ReactElement, useCallback, useContext, useState } from "react";

import { CurrencyName } from "../types";

export const InputValue = createContext({
  inputValue: "",
  changeInputValue: (() => null) as ({
    value,
    currency,
    percentage,
  }: {
    value?: string | undefined;
    currency: CurrencyName;
    percentage?: number | undefined;
  }) => void,
  error: null as Error | null,
});
export const useInputValue = () => useContext(InputValue);

export const InputValueProvider: FC<{ children: ReactElement | ReactElement[] }> = ({
  children,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<Error | null>(null);

  const changeInputValue = useCallback(
    ({
      value,
      currency,
      percentage,
    }: {
      value?: string;
      currency: CurrencyName;
      percentage?: number;
    }) => {
      try {
        setInputValue(value || "");
      } catch (error) {
        setError(error as Error);
      }
    },
    [setInputValue],
  );

  return (
    <InputValue.Provider value={{ inputValue, changeInputValue, error }}>
      {children}
    </InputValue.Provider>
  );
};
