import { useEffect } from "react";

const useMobileHeight = () => {
  useEffect(() => {
    const addCustomVH = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    addCustomVH();

    window.addEventListener("resize", addCustomVH);

    return () => {
      window.removeEventListener("resize", addCustomVH);
    };
  }, []);
};

export default useMobileHeight;
