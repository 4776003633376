import { useMutation } from "@tanstack/react-query";
import { SmartContract, useAddress } from "@thirdweb-dev/react";
import { BaseContract, utils } from "ethers/lib/ethers";
import { ThemeType } from "whitelabel-kit";

import { useGetSignature } from "./useGetSignature";

const gasMult = 1.2;

const estimationHelper =
  (contract: SmartContract<BaseContract>, value: string) =>
  async (fn: string, customGasLimit?: number | string, args?: any[]) => {
    const gasPrice = await contract.estimator.currentGasPriceInGwei();

    let originalTxCost = "0";
    try {
      originalTxCost = await contract.estimator.gasCostOf(fn, [
        ...(args ? args : []),
        {
          value: utils.parseEther(value),
        },
      ]);
    } catch (error) {
      originalTxCost = "0";
    }

    const customGasCost = customGasLimit ? (+gasPrice * +customGasLimit) / Math.pow(10, 9) : 0;

    return (Math.max(+originalTxCost, customGasCost) * gasMult).toString();
  };

const gasLimitHelper =
  (contract: SmartContract<BaseContract>, value: string) =>
  async (fn: string, customGasLimit?: number | string, args?: any[]) => {
    try {
      const gasLimit = await contract.estimator.gasLimitOf(fn, [
        ...(args ? args : []),
        {
          value: utils.parseEther(value),
        },
      ]);

      return Math.floor(
        Math.max(+utils.formatUnits(gasLimit, 0) * gasMult, +(customGasLimit || 0)),
      ).toString();
    } catch (error) {
      return customGasLimit?.toString() || "0";
    }
  };

export const useHandleAffiliate = (config: ThemeType["envs"] & { USDC_DECIMALS: number }) => {
  const params = new URLSearchParams(window.location.search);
  const hash = params.get("hash");
  const address = useAddress();
  const { isLoading: isVoucherLoading, mutateAsync: handleMint } = usePressMintButton(
    config,
    address,
  );
  const { isLoading: isVoucherLoadingAffiliate, mutateAsync: handleMintAffiliate } =
    usePressMintButtonWithAffiliate(config, address, hash || "");

  return hash?.length
    ? {
        isLoading: isVoucherLoadingAffiliate,
        mutateAsync: handleMintAffiliate,
      }
    : {
        isLoading: isVoucherLoading,
        mutateAsync: handleMint,
      };
};

export const usePressMintButtonWithAffiliate = (
  config: ThemeType["envs"] & { USDC_DECIMALS: number },
  address?: string,
  hash?: string,
) => {
  const { data, isLoading: isLoadingSignature } = useGetSignature({ hash });
  return useMutation(
    async ({
      value,
      currency,
      contract,
      isHaveVoucher,
      isEstimate,
    }: {
      value: string;
      currency: string;
      contract: SmartContract<BaseContract>;
      isHaveVoucher: boolean;
      isEstimate?: boolean;
    }) => {
      if (!data || isLoadingSignature) {
        return { isLoading: true };
      }

      const estimate = estimationHelper(contract, value);
      const estimateLimit = gasLimitHelper(contract, value);

      if (isHaveVoucher) {
        if (currency === "ETH") {
          if (isEstimate) {
            return await estimate("affiliateDeposit", config.AFFILIATE_DEPOSIT_GAS_LIMIT, [
              data.signature,
              data.address,
            ]);
          }
          return await contract.call("affiliateDeposit", [data.signature, data.address], {
            value: utils.parseEther(value),
            gasLimit: await estimateLimit("affiliateDeposit", config.AFFILIATE_DEPOSIT_GAS_LIMIT, [
              data.signature,
              data.address,
            ]),
          });
        } else {
          if (isEstimate) {
            return await estimate("affiliateDepositUSDC", 0, [
              utils.parseUnits(value, config.USDC_DECIMALS),
              data.signature,
              data.address,
            ]);
          }

          return await contract.call("affiliateDepositUSDC", [
            utils.parseUnits(value, config.USDC_DECIMALS),
            data.signature,
            data.address,
          ]);
        }
      }

      if (currency === "ETH") {
        if (isEstimate) {
          return await estimate("affiliateMint", config.AFFILIATE_MINT_GAS_LIMIT, [
            data.signature,
            data.address,
          ]);
        }
        return await contract.call("affiliateMint", [data.signature, data.address], {
          value: utils.parseEther(value),
          gasLimit: await estimateLimit("affiliateMint", config.AFFILIATE_MINT_GAS_LIMIT, [
            data.signature,
            data.address,
          ]),
        });
      } else {
        if (isEstimate) {
          return await estimate("affiliateMintWithUSDC", 0, [
            address,
            utils.parseUnits(value, config.USDC_DECIMALS),
            data.signature,
            data.address,
          ]);
        }

        return await contract.call("affiliateMintWithUSDC", [
          address,
          utils.parseUnits(value, config.USDC_DECIMALS),
          data.signature,
          data.address,
        ]);
      }
    },
  );
};

export const usePressMintButton = (
  config: ThemeType["envs"] & { USDC_DECIMALS: number },
  address?: string,
) =>
  useMutation(
    async ({
      value,
      currency,
      contract,
      isHaveVoucher,
      isEstimate,
    }: {
      value: string;
      currency: string;
      contract: SmartContract<BaseContract>;
      isHaveVoucher: boolean;
      isEstimate?: boolean;
    }) => {
      const estimate = estimationHelper(contract, value);
      const estimateLimit = gasLimitHelper(contract, value);

      if (isHaveVoucher) {
        if (currency === "ETH") {
          if (isEstimate) {
            return await estimate("deposit", config.DEPOSIT_GAS_LIMIT);
          }

          return await contract.call("deposit", [], {
            value: utils.parseEther(value),
            gasLimit: await estimateLimit("deposit", config.DEPOSIT_GAS_LIMIT),
          });
        } else {
          if (isEstimate) {
            return await estimate("depositUSDC", 0, [
              utils.parseUnits(value, config.USDC_DECIMALS),
            ]);
          }

          return await contract.call("depositUSDC", [
            utils.parseUnits(value, config.USDC_DECIMALS),
          ]);
        }
      }

      if (currency === "ETH") {
        if (isEstimate) {
          return await estimate("mint", config.MINT_GAS_LIMIT);
        }

        return await contract.call("mint", [], {
          value: utils.parseEther(value),
          gasLimit: await estimateLimit("mint", config.MINT_GAS_LIMIT),
        });
      } else {
        if (isEstimate) {
          return await estimate("mintWithUSDC", 0, [utils.parseUnits(value, config.USDC_DECIMALS)]);
        }

        return await contract.call("mintWithUSDC", [
          address,
          utils.parseUnits(value, config.USDC_DECIMALS),
        ]);
      }
    },
  );
