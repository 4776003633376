import { useBalance } from "@thirdweb-dev/react";
import { useWhitelabel, useWhitelabelContracts } from "whitelabel-kit";

import { floor } from "../helpers";

export const useUser = () => {
  const { stableAddress } = useWhitelabelContracts();
  const { data: ethBalance, isLoading: isEthLoading } = useBalance();
  const { data: usdcBalance, isLoading: isUsdcLoading } = useBalance(stableAddress);

  const {
    whitelabelValues: { envs },
  } = useWhitelabel();

  if (!ethBalance || !usdcBalance) {
    return { ethBalance: "0", usdcBalance: "0" };
  }

  return {
    ethBalance: floor(Number(ethBalance.displayValue), envs.ETH_DECIMALS),
    usdcBalance: floor(Number(usdcBalance.displayValue), envs.DECIMAL_VALUE),
    isLoading: isEthLoading || isUsdcLoading,
  };
};
