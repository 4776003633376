import Skeleton from "react-loading-skeleton";

export const Preloader = () => {
  return (
    <div>
      <Skeleton
        height={104}
        highlightColor={"var(--skeleton-highlight)"}
        baseColor={"var(--skeleton-base)"}
      />
      <Skeleton
        height={300}
        width={660}
        highlightColor={"var(--skeleton-highlight)"}
        baseColor={"var(--skeleton-base)"}
        borderRadius={25}
        containerClassName={"mt-[130px] mb-[50px] flex justify-center"}
      />
      <Skeleton
        count={3}
        height={86}
        width={660}
        highlightColor={"var(--skeleton-highlight)"}
        baseColor={"var(--skeleton-base)"}
        borderRadius={25}
        containerClassName={"flex flex-col items-center"}
      />
    </div>
  );
};
