import cx from "classnames";
import React from "react";

import { ButtonProps } from "../ui-kit-types";

const SecondaryBtn: React.FC<ButtonProps> = ({
  className,
  onClick,
  text,
  fullWidth,
  rounded = true,
}) => {
  return (
    <button
      className={cx(
        "bg-whitelabel-main-800 text-vitreus-luminous-green py-17 px-24 text-p3 uppercase hover:bg-whitelabel-main-600 transition-colors duration-300 font-medium",
        className,
        {
          "rounded-small": rounded,
          "w-full": fullWidth,
        },
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SecondaryBtn;
