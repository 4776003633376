import cx from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";

type IframeLinkProps = {
  to: string;
  text?: string;
  className?: string;
  textClassName?: string;
  children?: ReactNode;
  onClick?: (e: boolean) => void;
};

const IframeLink: React.FC<IframeLinkProps> = ({
  className,
  textClassName,
  to,
  text,
  children,
  onClick = () => {},
}) => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (isIframeOpen) {
      document.body.classList.add("_lock");
    } else {
      document.body.classList.remove("_lock");
    }
  }, [isIframeOpen]);

  useEffect(() => {
    const outsideClickHandler = function (e: MouseEvent) {
      if (iframeRef.current && !iframeRef.current?.contains(e.target as Node)) {
        setIsIframeOpen(false);
      }
    };
    document.addEventListener("click", outsideClickHandler);
    return () => {
      document.removeEventListener("click", outsideClickHandler);
    };
  }, []);

  return (
    <div ref={iframeRef} className={cx("relative", className)}>
      <div
        className={cx(
          "text-p3 text-vitreus-luminous-green py-8 cursor-pointer relative z-10 text-center flex flex-col items-center bg-black",
          textClassName,
        )}
        onClick={() => {
          setIsIframeOpen(true);
          onClick(true);
        }}
      >
        {children ?? text}
      </div>
      <div
        className={cx(
          "scrolling",
          "transition-all duration-300 sm:!fixed sm:!bottom-[80rem] sm:!left-0 sm:!w-full sm:!h-[calc(100*var(--vh,_1vh)_-_80rem)] sm:!top-[unset] sm:!translate-x-0 sm:!z-[9]",
          {
            "invisible opacity-0 translate-y-12": !isIframeOpen,
            "opacity-100 visible": isIframeOpen,
          },
        )}
      >
        <iframe title="iframe" className="w-full h-full overflow-scroll" src={to}></iframe>
      </div>
      <div
        className={cx("smin:!hidden w-full fixed h-full top-[73rem] left-0", {
          hidden: !isIframeOpen,
        })}
        onClick={() => {
          setIsIframeOpen(false);
          onClick(false);
        }}
      ></div>
    </div>
  );
};

export default IframeLink;
