import { TemplateImage, TemplateLink, TemplateTheme } from "whitelabel-kit";

import smallVoucher from "../../assets/images/logo.svg";
import smallLogo from "../../assets/images/logo-gray.svg";
import logo from "../../assets/images/mark-full-color.svg";
import { config } from "./environment.config";

interface BaseTheme extends TemplateTheme {
  links: { header: TemplateLink[] };
  images: {
    logo: TemplateImage;
    smallLogo: TemplateImage;
    smallVoucher: TemplateImage;
    backgroundAffiliate: TemplateImage;
    bigVoucher: TemplateImage;
  };
  voucherMeta: {
    image: { url: string; name: string };
    animation: { url: string; name: string };
    name: string;
  };
  texts: {
    connectDesktop: string;
    connectMobile: string;
    stableName: string;
    stableSymbol: string;
    stableShortName: string;
    mint: string;
    nativeName: string;
    currencyName: string;
    stageName: string;
    start: string;
    end: string;
    currentPrice: string;
    nextStage: string;
    discount: string;
    maxDeposit: string;
    totalProceeds: string;
    viewContracts: string;
    voucherName: string;
    allocationName: string;
    valueName: string;
    recentTransactions: string;
    roundNotStartedTooltip: string;
    allRoundsEndedTooltip: string;
  };
  envs: {
    DECIMAL_VALUE: number;
    LAUNCH_PRICE: number;
    DEPOSIT_GAS_LIMIT: number;
    MINT_GAS_LIMIT: number;
    AFFILIATE_MINT_GAS_LIMIT: number;
    AFFILIATE_DEPOSIT_GAS_LIMIT: number;
    AFFILIATE_CLAIM_GAS_LIMIT: number;
    MAIN_USDC_DECIMALS: number;
    ETH_DECIMALS: number;
    ROUND_PRICE_DECIMALS: number;
    PERCENTAGE_DECIMALS: number;
    VTRS_DECIMALS: number;
    DATE_FORMAT: string;
    apiUrlPresale: string;
  };
}

declare module "whitelabel-kit" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ThemeType extends BaseTheme {}
}

export const cssVars = {
  "header-user-icon": "#6DF1B2",
  "whitelabel-main": "#315452",
  "whitelabel-main-400": "#2c4c4a",
  "whitelabel-main-700": "#1c3230",
  "whitelabel-main-300": "#0E1818",
  "whitelabel-main-800": "#182a29",
  "vitreus-luminous-green": "#6df1b2",
  "whitelabel-main-600": "#223b39",
  "vitreus-black": "#0F1B1A",
  "vitreus-main-black-green": "#132221",
  "vitreus-black-800": "#495554",
  "vitreus-gray": "#748887",
  "vitreus-white": "#eef2f2",
  "vitreus-luminous-green-100": "#65f1c1",
  "vitreus-blue": "#8cdbff",
  "vitreus-dark-blue": "#1ca0c6",
  "vitreus-sky-blue": "#5acbff",
  "vitreus-red": "#ea3e3e",
  "skeleton-backgound": "#ffffff",
  "skeleton-base": "#ebebeb",
  "skeleton-highlight": "#d8d8d8",
};

export const baseTheme: BaseTheme = {
  links: {
    header: [
      {
        name: "NFT Purchase Agreement",
        href: "https://vitreus.io/nft-purchase-agreement/",
      },
      {
        name: "KYC & Audit",
        href: "https://vitreus.io/kyc-and-audit/",
      },
      {
        name: "FAQ",
        href: "https://vitreus.io/faq-presale/",
      },
    ],
  },
  images: {
    logo: {
      url: logo,
      name: "Header Logo",
    },
    smallLogo: {
      url: smallLogo,
      name: "Logo",
    },
    bigVoucher: {
      url: logo,
      name: "My Voucher Logo",
    },
    smallVoucher: {
      url: smallVoucher,
      name: "Deposit Logo",
    },
    backgroundAffiliate: {
      url: "https://bafybeid5iq5bq53lr7rfwi252sjjmjlgvqjvkxk6sk2z3lgeigukmaytfq.ipfs.w3s.link/Linebirgitte-Art-Nouveau-Apple.32.png",
      name: "Login background",
    },
  },
  texts: {
    connectDesktop: "Connect your wallet",
    connectMobile: "Connect",
    stableName: "USDC",
    stableShortName: "USD",
    stableSymbol: "$",
    nativeName: "ETH",
    currencyName: "VTRS",
    mint: "mint",
    stageName: "Presale",
    start: "Start",
    end: "End",
    currentPrice: "Current Price",
    nextStage: "Next Stage",
    discount: "Discount ",
    maxDeposit: "Max Deposit",
    totalProceeds: "Total Proceeds",
    viewContracts: "View Contracts/NFTs",
    voucherName: "Voucher",
    allocationName: "Allocation",
    valueName: "Value",
    recentTransactions: "Recent Transactions",
    roundNotStartedTooltip: "Round has not started",
    allRoundsEndedTooltip: "All rounds are over",
  },
  envs: {
    DECIMAL_VALUE: 4,
    LAUNCH_PRICE: 100,
    DEPOSIT_GAS_LIMIT: 210000,
    MINT_GAS_LIMIT: 270000,
    AFFILIATE_MINT_GAS_LIMIT: 370000,
    AFFILIATE_DEPOSIT_GAS_LIMIT: 3100000,
    AFFILIATE_CLAIM_GAS_LIMIT: 500000,
    MAIN_USDC_DECIMALS: 6,
    ETH_DECIMALS: 5,
    ROUND_PRICE_DECIMALS: 4,
    PERCENTAGE_DECIMALS: 2,
    VTRS_DECIMALS: 2,
    DATE_FORMAT: "MMMM Do, hh:mmA",
    apiUrlPresale: `${config.PRESALE_API}`,
  },
  fonts: {
    active: {
      name: "DM Sans",
    },
    list: [
      {
        name: "DM Sans",
      },
      {
        name: "'Fuggles', cursive",
        googleUrl: "https://fonts.googleapis.com/css2?family=Fuggles&display=swap",
      },
      {
        name: "'Mooli', sans-serif",
        googleUrl: "https://fonts.googleapis.com/css2?family=Mooli&display=swap",
      },
      {
        name: "'Skranji', cursive",
        googleUrl: "https://fonts.googleapis.com/css2?family=Skranji&display=swap",
      },
      {
        name: "'Agbalumo'",
        googleUrl: "https://fonts.googleapis.com/css2?family=Agbalumo&display=swap",
      },
      {
        name: "'Edu TAS Beginner'",
        googleUrl:
          "https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400;500;600;700&display=swap",
      },
      {
        name: "'Kdam Thmor Pro'",
        googleUrl: "https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap",
      },
      {
        name: "'Playfair Display', serif",
        googleUrl:
          "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap",
      },
    ],
  },
  helmet: {
    title: "Vitreus App",
    favicon: { url: smallLogo },
    meta: [
      {
        property: "og:type",
        content: "article",
      },
      {
        property: "og:title",
        content: "VITREUS Progressive Presale",
      },
      {
        property: "og:description",
        content: "Progressive Presale page of the VITREUS Utility Token VTRS.",
      },
      {
        property: "og:url",
        content: "https://vitreus.io/?page_id=2724",
      },
      {
        property: "og:site_name",
        content: "VITREUS",
      },
      {
        property: "article:publisher",
        content: "https://www.facebook.com/VitreusChain",
      },
      {
        property: "article:modified_time",
        content: "2023-06-16T15:47:16+00:00",
      },
      {
        property: "og:image",
        content: "https://vitreus.io/wp-content/uploads/2023/02/Coin_Twitter_Card.jpg",
      },
      {
        property: "og:image:width",
        content: "800",
      },
      {
        property: "og:image:height",
        content: "418",
      },
      {
        property: "og:image:type",
        content: "image/jpeg",
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        property: "twitter:title",
        content: "VTRS Progressive Presale",
      },
      {
        property: "twitter:description",
        content: "Progressive Presale page of the VITREUS Utility Token VTRS.",
      },
      {
        property: "twitter:image",
        content: "https://vitreus.io/wp-content/uploads/2023/02/Coin_Twitter_Card.jpg",
      },
      {
        property: "twitter:site",
        content: "@VitreusChain",
      },
    ],
  },
  dAppMeta: {
    name: "Vitreus Presale dApp",
    logo: {
      url: "https://bafybeif23wbgdywp55lyj3p7os2bzphuwyuhrq53f3jz5hu7aq6pgmukam.ipfs.w3s.link/vitreus-logo.png",
    },
    url: "https://presale.vitreus.io/",
    description: "Vitreus Presale dApp",
  },
  voucherMeta: {
    image: {
      url: "https://bafybeieql2plfr4sa3gkotozo2scm5plz5x4kpnpwwrpx3ltufcfajuomy.ipfs.w3s.link/openSeaImage.png",
      name: "Voucher Image",
    },
    animation: {
      url: "https://bafybeidwjf5ktpijuwhfwg3rzjulijfxl2zktpjjwnqinhm5ll7r6gnbsq.ipfs.w3s.link/openSeaVideo.mp4",
      name: "Voucher Animation",
    },
    name: "Whitelabel Voucher",
  },
};
