import cx from "classnames";

import { ReactComponent as LoaderSvg } from "../../../assets/images/spinner.svg";

type LoaderProps = {
  className?: string;
};

const Loader: React.FC<LoaderProps> = ({ className }) => {
  return (
    <div className={cx("flex items-center justify-center animate-spin-slow", className)}>
      <LoaderSvg className="icon-success" />
    </div>
  );
};

export default Loader;
