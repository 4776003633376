import React from "react";

const BackImages = () => {
  return (
    <>
      <div className="absolute bilkBLock right-0 top-0" />
      <div className="absolute bilkBLock left-0 top-3/4 -translate-y-1/2" />
    </>
  );
};

export default BackImages;
