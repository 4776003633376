import { useQuery } from "@tanstack/react-query";
import { Contract, providers } from "ethers/lib/ethers";
import { ThemeType } from "whitelabel-kit";

import vitreusVoucherJSON from "../abi/VitreusVoucher.json";
import { Deposit } from "../models/Deposit";
import { PresaleRound } from "../models/PresaleRound";

export const useGetDeposits = (
  config: ThemeType["envs"] & { VOUCHER_ADDRESS: string; USDC_DECIMALS: number },
  presaleRounds?: Record<string, PresaleRound>,
  provider?: providers.Provider,
) =>
  useQuery(
    ["getDeposits", config.VOUCHER_ADDRESS],
    async () => {
      if (!presaleRounds) return null;
      if (!config.VOUCHER_ADDRESS) return null;

      try {
        const response = await fetch(config.apiUrlPresale + "/event/all");
        const data = await response.json();

        const deposits: Deposit[] = data.data.map(
          ({ data, _id }: { data: any; _id: string }) =>
            new Deposit(config, presaleRounds, data.data, _id),
        );

        return deposits;
      } catch (error) {
        if (!provider) {
          return null;
        }

        const contract = new Contract(config.VOUCHER_ADDRESS, vitreusVoucherJSON.abi, provider);

        const depositsResponse = await contract.queryFilter(contract.filters.Deposit());
        const deposits = depositsResponse
          .map(({ args }) => new Deposit(config, presaleRounds, args))
          .reverse();

        return deposits;
      }
    },
    { enabled: !!presaleRounds },
  );
