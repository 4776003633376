import { commifyWithDecimals } from "@presale-app/app/helpers";
import { useVoucher } from "@presale-app/app/hooks";
import { useConnectionStatus } from "@thirdweb-dev/react";
import { utils } from "ethers";
import { Tooltip } from "vit-ui-kit";
import { useWhitelabel } from "whitelabel-kit";

import { useUser } from "../../hooks/useUser";
import { MintBtn } from "../../ui-kit";

type BalanceBlockProps = {
  onClick: () => void;
};

const BalanceBlock: React.FC<BalanceBlockProps> = ({ onClick }) => {
  const { ethBalance, usdcBalance } = useUser();
  const status = useConnectionStatus();
  const { voucher } = useVoucher();

  const {
    whitelabelValues: {
      texts: {
        mint,
        connectDesktop,
        stableName,
        nativeName,
        roundNotStartedTooltip,
        allRoundsEndedTooltip,
      },
    },
  } = useWhitelabel();

  return (
    <div className="flex items-center sm:!flex-wrap sm:!gap-y-16">
      <Tooltip
        name="balance-tooltip"
        tooltip={
          !voucher?.currentPresaleRound ||
          (voucher &&
            new Date(
              Number(utils.formatUnits(voucher?.currentPresaleRound.startTimeDate, 0)) * 1000,
            ).getTime() > new Date().getTime())
            ? roundNotStartedTooltip
            : new Date(
                Number(utils.formatUnits(voucher?.currentPresaleRound.endTimeDate, 0)) * 1000,
              ).getTime() < new Date().getTime()
            ? allRoundsEndedTooltip
            : ""
        }
      />
      <MintBtn
        className="balanceBtn balance-tooltip"
        text={mint}
        onClick={onClick}
        disabled={
          !voucher?.currentPresaleRound ||
          (!!voucher &&
            (new Date(
              Number(utils.formatUnits(voucher?.currentPresaleRound.startTimeDate, 0)) * 1000,
            ).getTime() > new Date().getTime() ||
              new Date(
                Number(utils.formatUnits(voucher?.currentPresaleRound.endTimeDate, 0)) * 1000,
              ).getTime() < new Date().getTime()))
        }
      />
      {status !== "connected" && (
        <span className="text-p3 uppercase text-gray ml-8 sm:!hidden sm:!ml-auto sm:!text-p4">
          {connectDesktop}
        </span>
      )}
      <div className="flex items-start gap-8 ml-auto sm:!flex-[0_1_50%] sm:!ml-0">
        <span className="text-white text-sh1 sm:!text-sh2">{utils.commify(usdcBalance)}</span>
        <span className="text-gray text-p3 sm:!text-p4">{stableName}</span>
      </div>
      <div className="flex items-start gap-8 ml-24 sm:!flex-[0_1_50%] sm:!ml-0">
        <span className="text-white text-sh1 sm:!text-sh2">{utils.commify(ethBalance)}</span>
        <span className="text-gray text-p3 sm:!text-p4">{nativeName}</span>
      </div>
    </div>
  );
};

export default BalanceBlock;
