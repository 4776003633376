import { useAddress, useSDK } from "@thirdweb-dev/react";
import { useWhitelabel, useWhitelabelContracts } from "whitelabel-kit";

import { useGetDeposits } from "../actions/useGetDeposits";
import { useGetVoucher } from "../actions/useGetVoucher";

export const useVoucher = () => {
  const sdk = useSDK();
  const address = useAddress();

  const {
    whitelabelValues: { envs },
  } = useWhitelabel();

  const { addresses, stableDecimals } = useWhitelabelContracts();

  const {
    data: voucher,
    isLoading: isVoucherLoading,
    refetch: refetchVoucher,
  } = useGetVoucher(
    { ...envs, VOUCHER_ADDRESS: addresses?.voucher || "", USDC_DECIMALS: stableDecimals },
    sdk?.getProvider(),
    address,
  );

  const {
    data: deposits,
    isLoading: isDepositsLoading,
    refetch: refetchDeposits,
  } = useGetDeposits(
    { ...envs, VOUCHER_ADDRESS: addresses?.voucher || "", USDC_DECIMALS: stableDecimals },
    voucher?.presaleRounds,
    sdk?.getProvider(),
  );

  return {
    voucher,
    isVoucherLoading,
    refetch: () => {
      refetchVoucher();
      refetchDeposits();
    },
    deposits,
    isDepositsLoading,
  };
};
