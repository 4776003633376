import { BigNumber, constants, utils } from "ethers";
import { ThemeType } from "whitelabel-kit";

import { floor, formatTime, formatUsdc } from "../../helpers";
import { IPresaleRound } from "./types";
export class PresaleRound implements IPresaleRound {
  allocation: string;
  endTime: string;
  endTimeDate: any;
  startTimeDate: any;
  endingId: string;
  maximumDeposit: string;
  minimumDeposit: string;
  price: string;
  startTime: string;
  startingId: string;
  totalDeposits: string;
  round: string;
  soldVtrs: string;
  allVtrs: string;
  progress: number;
  discount: string;

  constructor(
    config: ThemeType["envs"] & { USDC_DECIMALS: number },
    presaleRound?: any,
    round?: BigNumber,
  ) {
    this.allocation = utils.formatUnits(presaleRound?.allocation, 0);
    this.endTimeDate = presaleRound?.endTime;
    this.endTime = formatTime(presaleRound?.endTime, config);
    this.endingId = utils.formatUnits(presaleRound?.endingId, 0);
    this.maximumDeposit = formatUsdc(presaleRound?.maximumDeposit, config);
    this.minimumDeposit = formatUsdc(presaleRound?.minimumDeposit, config);
    this.price = formatUsdc(presaleRound?.price, config);
    this.startTime = formatTime(presaleRound?.startTime, config);
    this.startTimeDate = presaleRound?.startTime;
    this.startingId = utils.formatUnits(presaleRound?.startingId, 0);
    this.totalDeposits = formatUsdc(presaleRound?.totalDeposits, config);
    this.round = utils.formatUnits(round || constants.Zero, 0);
    // TODO_ENV: 2 - environment: VTRS_DECIMALS
    this.soldVtrs = floor(presaleRound?.totalDeposits / presaleRound?.price, 2);
    this.allVtrs = utils.formatUnits(BigNumber.from(presaleRound?.allocation), 0);
    this.progress = Number(this.soldVtrs) / Number(this.allVtrs);
    this.discount = utils.commify(
      ((1 - Number(this.price) / config.LAUNCH_PRICE) * 100).toFixed(config.DECIMAL_VALUE),
    );
  }
}
