import cx from "classnames";

import { ButtonProps } from "../ui-kit-types";

const BlueBtn: React.FC<ButtonProps> = ({ className, onClick, text, fullWidth }) => {
  return (
    <button
      className={cx(
        "text-p3 py-17 px-24 uppercase rounded-b-large bg-vitreus-blue transition-colors duration-300 hover:bg-vitreus-sky-blue text-black",
        className,
        {
          "w-full": fullWidth,
        },
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default BlueBtn;
