import { BigNumber, constants, utils } from "ethers";
import { ThemeType } from "whitelabel-kit";

import { commifyWithDecimals, floor, formatUsdcFloor } from "../../helpers";
import { PresaleRound } from "../PresaleRound";
import { ICurrentVoucher } from "./types";

export class CurrentVoucher implements ICurrentVoucher {
  id: string;
  allocation: string;
  value: string;

  constructor(
    config: ThemeType["envs"] & { USDC_DECIMALS: number },
    currentVoucher: { id: any; values: any },
    presaleRounds: Record<string, PresaleRound>,
  ) {
    const value = currentVoucher.values.reduce((acc: BigNumber, value: BigNumber) => {
      return acc.add(value);
    }, constants.Zero);

    const allocation = currentVoucher.values.reduce(
      (acc: number, value: BigNumber, index: number) => {
        return (
          acc +
          Number(utils.formatUnits(value, config.USDC_DECIMALS)) /
            Number(presaleRounds[index].price)
        );
      },
      0,
    );

    this.id = utils.formatUnits(currentVoucher.id, 0);
    this.value = formatUsdcFloor(value, config);
    // TODO_ENV: 2 - environment: VTRS_DECIMALS
    this.allocation = commifyWithDecimals(+floor(allocation, 2), 2);
  }
}
