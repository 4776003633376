import { BigNumber, utils } from "ethers";
import moment from "moment";
import { ThemeType } from "whitelabel-kit";

const videoExtensions = [".mp4"];

export const formatUsdc = (
  value: BigNumber,
  config: ThemeType["envs"] & { USDC_DECIMALS: number },
) =>
  utils.commify(
    Number(utils.formatUnits(value, config.USDC_DECIMALS)).toFixed(config.DECIMAL_VALUE),
  );

export const formatUsdcFloor = (
  value: BigNumber,
  config: ThemeType["envs"] & { USDC_DECIMALS: number },
) => {
  const result = utils.commify(
    (
      +String(
        +utils.formatUnits(value, config.MAIN_USDC_DECIMALS) * pow(10, config.MAIN_USDC_DECIMALS),
      ).split(".")[0] / pow(10, config.MAIN_USDC_DECIMALS)
    ).toFixed(config.MAIN_USDC_DECIMALS),
  );

  return result;
};

export const floor = (value: number, decimals: number) =>
  (+String(value * pow(10, decimals)).split(".")[0] / pow(10, decimals)).toFixed(decimals);

export const formatTime = (value: BigNumber, config: ThemeType["envs"]) =>
  moment(Number(utils.formatUnits(value, 0)) * 1000).format(config.DATE_FORMAT);

export const round = (value: number, decimals: number) => {
  let result = utils.commify(value.toFixed(decimals));
  for (let i = result.split(".")[1]?.length || 0; i < decimals; i++) {
    result += "0";
  }
  return result;
};

export const formatWithLetter = (commifyValue: string) => {
  const discharge = commifyValue.split(",").length - 1;
  let letter = "";

  switch (discharge) {
    case 1:
      letter = "K";
      break;
    case 2:
      letter = "M";
      break;
    case 3:
      letter = "B";
      break;
    default:
      letter = "";
      break;
  }

  return commifyValue.split(",")[0] + letter;
};

export const commifyWithDecimals = (value: number, decimals: number) => {
  return utils.commify(+floor(value * 10 ** decimals, decimals) / 10 ** decimals);
};

export const pow = (x: number, n: number) => {
  let result = x;
  for (let i = 1; i < n; i++) {
    result *= x;
  }
  return result;
};

export const scrollTo = (element: HTMLElement, to: number, duration: number) => {
  const start = element.scrollTop,
    change = to - start;
  let currentTime = 0;
  const increment = 20;

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const isVideo = (str: string) => {
  const arr = str.split(".");
  return videoExtensions.includes("." + arr[arr.length - 1]);
};
