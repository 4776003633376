import cx from "classnames";
import { memo, useCallback, useState } from "react";
import { GhostButton } from "vit-ui-kit";
import { AdminPanel as AdminPanelKit } from "whitelabel-kit";

import { WhiteLabelGuard } from "../../components/WhiteLabelGuard/WhiteLabelGuard";

export const AdminPanel = memo(() => {
  const [isHidden, setIsHidden] = useState(false);

  const toggle = useCallback(() => {
    setIsHidden((oldState) => !oldState);
  }, []);

  return (
    <WhiteLabelGuard>
      <div
        className={cx(
          "relative h-[calc(100vh-105px)] p-24 bg-[var(--whitelabel-main-300)] border-r-whitelabel-main-800 border-r-2 transition-all left-0",
          {
            "w-[60px]": isHidden,
            "w-full": !isHidden,
          },
        )}
      >
        <GhostButton
          text={isHidden ? ">" : "<"}
          className="absolute top-10 right-0 translate-x-1/2 !bg-[var(--whitelabel-main-300)] border-whitelabel-main-800 border-2 !text-whitelabel-main !text-sh2 !px-14 !py-6"
          onClick={toggle}
        />
        {!isHidden && (
          <>
            <h2 className="text-gray mb-20 text-h5">WHITELABEL ADMIN BOARD</h2>
            <AdminPanelKit className="!h-[calc(100%-120px)]" tabsCount={3} />
          </>
        )}
      </div>
    </WhiteLabelGuard>
  );
});
