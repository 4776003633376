import "react-loading-skeleton/dist/skeleton.css";

import { Preloader } from "@presale-app/app/ui-kit/Preloader";
import cx from "classnames";
import { useCallback, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { StrokeButton } from "vit-ui-kit";
import { useWhitelabel } from "whitelabel-kit";

import useMobileHeight from "../actions/useMobileHeight";
import BackImages from "../components/BackImages/BackImages";
import Header from "../components/Header/Header";
import Main from "../components/Main/Main";
import MobileFooter from "../components/MobileFooter/MobileFooter";

const Landing = () => {
  useMobileHeight();

  const container = useRef<HTMLDivElement>(null);
  const { isLoaded } = useWhitelabel();

  const checkDisclaimer = () => {
    if (localStorage.getItem("disclaimer")) {
      return "";
    }

    return "disclaimerModal";
  };
  const [activeModal, setActiveModal] = useState<string>(() => checkDisclaimer());

  const scrollToTop = useCallback(() => {
    container.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (!isLoaded) return <Preloader />;

  return (
    <div
      className={cx("landing pt-[105px] flex-auto flex flex-col items-stretch bg-black", {
        "h-[100vh] overflow-hidden": activeModal !== "",
      })}
    >
      <Header />

      <div className="z-[2] flex h-[calc(100vh-105px)]">
        <Outlet />

        <div className="h-full w-full overflow-auto relative z-[-1]" ref={container}>
          <Main
            className="mt-[130rem] sm:!mt-[50rem] flex-auto"
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            parentRef={container}
          />
          <StrokeButton
            className="uppercase fixed bottom-32 right-32 !bg-black"
            text="go to top"
            onClick={scrollToTop}
          />
        </div>
      </div>

      <BackImages />
      <MobileFooter />
    </div>
  );
};

export { Landing };
