import { createContext, FC, ReactElement, useCallback, useContext, useMemo, useState } from "react";

import { CurrencyName } from "../types";

export const Currency = createContext({
  currency: "ETH" as CurrencyName,
  switchCurrency: (() => null) as () => void,
  isEth: true,
});
export const useCurrency = () => useContext(Currency);

export const CurrencyProvider: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  const [currency, setCurrency] = useState<CurrencyName>("ETH");
  const switchCurrency = useCallback(
    () => setCurrency((oldCurrency) => (oldCurrency === "ETH" ? "USDC" : "ETH")),
    [setCurrency],
  );
  const isEth = useMemo(() => currency === "ETH", [currency]);

  return (
    <Currency.Provider value={{ currency, switchCurrency, isEth }}>{children}</Currency.Provider>
  );
};
