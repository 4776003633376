import { useQuery } from "@tanstack/react-query";
import { SmartContract } from "@thirdweb-dev/sdk";
import { BaseContract } from "ethers/lib/ethers";

export const useGetUSDC = (VOUCHER_ADDRESS?: string, contract?: SmartContract<BaseContract>) =>
  useQuery(
    ["getUSDC", VOUCHER_ADDRESS],
    async () => {
      if (!VOUCHER_ADDRESS) return;
      return await contract?.erc20.allowance(VOUCHER_ADDRESS);
    },
    { enabled: !!contract && !!VOUCHER_ADDRESS },
  );
