/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import { BigNumber, constants, Contract, providers, utils } from "ethers/lib/ethers";
import { ThemeType, useWhitelabel } from "whitelabel-kit";

import vitreusVoucherJSON from "../abi/VitreusVoucher.json";
import { floor, formatUsdcFloor, pow, round } from "../helpers";
import { CurrentVoucher } from "../models/CurrentVoucher";
import { PresaleRound } from "../models/PresaleRound";

const getCurrentVoucher = async (
  config: ThemeType["envs"] & { USDC_DECIMALS: number },
  contract: Contract,
  presaleRounds: Record<string, PresaleRound>,
  address?: string,
) => {
  if (!address) return null;

  const id = utils.formatUnits(await contract.tokenOf(address), 0);
  if (id === "0") return null;

  const values = await contract.statsOf(id);

  return new CurrentVoucher(
    config,
    {
      id,
      values,
    },
    presaleRounds,
  );
};

export const useGetVoucher = (
  config: ThemeType["envs"] & { VOUCHER_ADDRESS: string; USDC_DECIMALS: number },
  provider?: providers.Provider,
  address?: string,
) => {
  const {
    whitelabelValues: { envs },
  } = useWhitelabel();
  return useQuery(
    ["getVoucher", config.VOUCHER_ADDRESS, address],
    async () => {
      if (!config.VOUCHER_ADDRESS) return null;

      const contract = new Contract(config.VOUCHER_ADDRESS, vitreusVoucherJSON.abi, provider);
      const { estimateUSDCAmount, estimateETHAmount } = contract;

      const currentPresaleRoundNumber = utils.formatUnits(
        (await contract.presaleRoundNumber()).add(BigNumber.from(1)),
        0,
      );

      const presaleRoundsResponse = await contract.getAllPresaleRounds();
      const presaleRounds: Record<string, PresaleRound> = presaleRoundsResponse.reduce(
        (acc: Record<string, PresaleRound>, round: any, index: number) => ({
          ...acc,
          [index]: new PresaleRound(config, round),
        }),
        {} as Record<string, PresaleRound>,
      );

      const currentVoucher = await getCurrentVoucher(config, contract, presaleRounds, address);

      const nextRound = presaleRounds[currentPresaleRoundNumber];
      const totalProceeds = formatUsdcFloor(
        presaleRoundsResponse
          .slice(0, Number(currentPresaleRoundNumber))
          .reduce(
            (acc: BigNumber, { totalDeposits }: { totalDeposits: BigNumber }) =>
              acc.add(totalDeposits),
            constants.Zero,
          ),
        config,
      );

      const allPresaleRoundCount = utils.formatUnits(await contract.getPresaleRoundAmount(), 0);

      let currentPresaleRound,
        currentPresaleRoundPrice,
        currentPresaleRoundDiscount,
        currentPresaleRoundSoldVtrs,
        currentPresaleRoundAllVtrs,
        vtrsSoldPercentage,
        nextPrice,
        availableDepositNumber,
        availableDepositNumberInETH,
        availableDeposit;

      try {
        currentPresaleRound = new PresaleRound(config, await contract.getCurrentPresaleRound());

        currentPresaleRoundPrice = round(+currentPresaleRound.price, envs.ROUND_PRICE_DECIMALS);
        currentPresaleRoundDiscount = (+currentPresaleRound.discount).toFixed(
          envs.PERCENTAGE_DECIMALS,
        );
        currentPresaleRoundSoldVtrs = round(+currentPresaleRound.soldVtrs, envs.VTRS_DECIMALS);
        currentPresaleRoundAllVtrs = round(+currentPresaleRound.allVtrs, envs.VTRS_DECIMALS);
        vtrsSoldPercentage = (+floor(
          (Number(currentPresaleRound.soldVtrs) / Number(currentPresaleRound.allVtrs)) *
            10 ** Number(envs.VTRS_DECIMALS),
          envs.PERCENTAGE_DECIMALS,
        )).toFixed(envs.VTRS_DECIMALS);

        nextPrice = nextRound
          ? round(+nextRound.price, envs.ROUND_PRICE_DECIMALS)
          : currentPresaleRoundPrice;

        availableDepositNumber = await contract.availableDeposit(currentVoucher?.id || 0);
        availableDeposit = formatUsdcFloor(availableDepositNumber, config);

        const { _hex } = await estimateETHAmount(Number(availableDepositNumber));
        const bigNumber = utils.formatUnits(_hex, 0);
        availableDepositNumberInETH = Number(bigNumber) / pow(10, 18);
      } catch (error) {
        //
      }

      return {
        contract,
        currentPresaleRound,
        currentPresaleRoundNumber: currentPresaleRoundNumber,
        currentPresaleRoundSoldVtrs,
        currentPresaleRoundAllVtrs,
        currentPresaleRoundPrice,
        currentPresaleRoundDiscount,
        allPresaleRoundCount,
        totalProceeds,
        nextPrice,
        presaleRounds,
        currentVoucher,
        availableDeposit,
        availableDepositNumber: availableDepositNumber / pow(10, config.USDC_DECIMALS),
        availableDepositNumberInETH,
        vtrsSoldPercentage,
        estimateUSDCAmount,
        estimateETHAmount,
      };
    },
    { enabled: !!provider },
  );
};
