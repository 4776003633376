import { useMutation } from "@tanstack/react-query";

import { config } from "../config/environment.config";

export const useCheckUrl = () =>
  useMutation(async () => {
    const params = new URL(window.location.href).searchParams;

    const response = await fetch(config.affiliateApiUrl + "/affiliate/verify-hash?" + params);

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  });
