import { useVoucher } from "@presale-app/app/hooks";
import { useWhitelabelContracts } from "whitelabel-kit";

import BalanceBlock from "../BalanceBlock/BalanceBlock";
import Loader from "../Loader/Loader";
import ModalsContainer from "../ModalsContainer/ModalsContainer";
import StatBlock from "../StatBlock/StatBlock";
import VoucherBig from "../VoucherBig/VoucherBig";
import VoucherList from "../VoucherList/VoucherList";
import WidthWrapper from "../WidthWrapper/WidthWrapper";

type MainProps = {
  className?: string;
  activeModal: string;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
  parentRef?: React.RefObject<HTMLDivElement>;
};

const Main: React.FC<MainProps> = ({ className, activeModal, setActiveModal, parentRef }) => {
  const { voucher } = useVoucher();
  const { addresses } = useWhitelabelContracts();

  const isShowData =
    addresses?.voucher && voucher?.allPresaleRoundCount && voucher?.allPresaleRoundCount !== "0";

  if (!voucher && addresses?.voucher) {
    return <Loader className="p-20" />;
  }

  const changeOverflow = () => {
    if (parentRef?.current) {
      parentRef.current.style.overflow = "hidden";
    }
  };

  return (
    <div className={className}>
      <WidthWrapper className="max-w-small pb-[300rem]">
        {isShowData && (
          <BalanceBlock
            onClick={() => {
              changeOverflow();
              setActiveModal("transactionModal");
            }}
          />
        )}
        <StatBlock />
        {isShowData && <VoucherBig />}
        {isShowData && <VoucherList />}

        <ModalsContainer
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          parentRef={parentRef}
        />
      </WidthWrapper>
    </div>
  );
};

export default Main;
